import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserdataService } from 'src/app/Userdata.service';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { ChangeLocService } from './ChangeLoc.service';
import { RestService } from 'src/app/services/rest.service';
import { Location } from "@angular/common";
import * as $ from "jquery";

@Component({
      selector: 'app-root',
      templateUrl: './app.component.html',
      styleUrls: ['./app.component.scss']
})
export class AppComponent {

      title = 'Maven';
      showloader:any=false;
      subscription: Subscription;
      routePage:any;
      changemaximopoup:any=0;
      
      token:any='';
      MaximoList:any=[];
      Maximo_ID:any='';
      MaximoName:any='';
      Org_list:any=[];
      Org_id:any='';
      OrgName:any='';
      Site_list:any=[];
      Site_id:any='';
      SiteName:any='';
      closebtnpop:any=0;


      //header data
      mainfirstheader='0';
      Headertypeshow:any='1';
      sidebarpopup:any=0;
      User_type:any=0;
      userdetils = {
          "firstname": "",
          "lastname": "",
          "username": "",
          "user_id":'',
          "image":""
      };
      showactive:any=0;
      showmaximoch:any=0;
      sidebaractive:any=0;
  	constructor(public route: Router,public rest: RestService,public chlocation : ChangeLocService,public location: Location,private loaderService: LoaderService,public maximoService: UserdataService) 
      {
            this.routePage=this.route.url; 
            this.setheaderfunction(this.route.url);
              //this.loadpagefun();
            if(localStorage.getItem('isloginuser') != "1")
            {
                  this.route.navigate(["/login"]);    
            }
            else
            {
                  this.token=localStorage.getItem('usertoken');
                  this.loadpagefuntowew();
            }
            if(localStorage.getItem('Setup_value')=='0')
            {
                  this.loagerchnagefunction('2');  
            }
            this.subscription = this.loaderService.getloader().subscribe(product => {
                  this.loagerchnagefunction(product.loc);
	      });
            this.route.events.subscribe((val) => {
                  this.routePage=this.route.url; 

                  this.setheaderfunction(this.route.url);
            });
            this.loadmaximodata();

 	}
 	loagerchnagefunction(ty:any)
 	{
            console.log(ty);
         		if(ty=='true')
         		{
         			this.showloader=true;
         		}
            else if(ty=='3')
            {
                  this.changemaximopoup=1;
                  this.closebtnpop=1;
                  this.loadpagefuntowew();
            }
            else if(ty=='4')
            {
                this.loadpagefuntowew();
                this.loadmaximodata();
            }
            else if(ty=='2')
            {
                  this.changemaximopoup=1;
                  this.closebtnpop=1;
            }
            else if(ty=='1')
            {
                  this.changemaximopoup=1;
                  this.loadmaximodata();
                 
            }
            else if(ty=='0')
            {
                  this.changemaximopoup=0;
            }
 		else
 		{
			this.showloader=false;
 		}	
 	}
      loadmaximodata(){
             console.log('just  login');
            let maximodata = JSON.parse(localStorage.getItem('Maximo_data') || '{}');
            
            if(maximodata)
            {
                  this.Maximo_ID=maximodata.mid;

                  if(this.Maximo_ID == '' || this.Maximo_ID == undefined || this.Maximo_ID == null)
                  {
                        this.Maximo_ID='';
                        this.MaximoName='';
                        this.Org_id='';
                        this.OrgName='';
                        this.Site_id='';
                        this.SiteName='';
                         
                  }
                  else
                  {
                        this.Maximo_ID=maximodata.mid;
                        this.MaximoName=maximodata.mname;
                        this.Org_id=maximodata.oid;
                        this.OrgName=maximodata.oname;
                        this.Site_id=maximodata.sid;
                        this.SiteName=maximodata.sname;
                        this.Changemaximotop(this.Maximo_ID,'2');   
                  }
                  
            }
            else
            {
                 this.Maximo_ID='';
                  this.MaximoName='';
                  this.Org_id='';
                  this.OrgName='';
                  this.Site_id='';
                  this.SiteName='';
            }
            
      }
      loadpagefun()
      {
            if(this.routePage=="/")
            {
                  if(localStorage.getItem('isloginuser') == "1")
                  {
                        this.route.navigate(["/home"]);    
                  }
            }
            else if(this.routePage=="/login")
            {
                  if(localStorage.getItem('isloginuser') == "1")
                  {
                        this.route.navigate(["/home"]);    
                  }
            }
            else if(this.routePage=="/signup")
            {
                  if(localStorage.getItem('isloginuser') == "1")
                  {
                        this.route.navigate(["/home"]);    
                  }
            }
            else if(this.routePage=="/report")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/home")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/home2")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/planning")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/work-management")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/assets-management")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/clients")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/changepriority")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/add-new-user")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/add-new-client")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/users")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/editprofile")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }
            else if(this.routePage=="/edituser")
            {
                  if(localStorage.getItem('isloginuser') != "1")
                  {
                        this.route.navigate(["/login"]);    
                  }
            }


     }
     setheaderfunction(page:any)
     {
        
          if(page=="/login")
          {
               this.Headertypeshow=1;
               this.showactive=0;
               this.showmaximoch=0;
               this.sidebaractive=0;
               this.mainfirstheader='1';
               
          }
          else if(page=="/")
          {

               this.Headertypeshow=2; 
               this.showactive=1;
               this.showmaximoch=1;
               this.sidebaractive=0;
               this.mainfirstheader='1';
               
          }
          else if(page=="/report")
          {
           
            this.Headertypeshow=1;
            this.showactive=2;
            this.showmaximoch=1; 
            this.sidebaractive=1;
            this.mainfirstheader='1';
          }
          else if(page=="/home")
          {
                 this.Headertypeshow=2; 
                 this.showactive=1;
                 this.showmaximoch=1;
                 this.sidebaractive=0;
                 this.mainfirstheader='1';
            }
            else if(page=="/home2")
            {
                  this.Headertypeshow=1;
                  this.showactive=1;
                  this.showmaximoch=0;
                  this.sidebaractive=0;
                  this.mainfirstheader='1';
            }
            else if(page=="/planning")
            {
                 this.Headertypeshow=2; 
                 this.showactive=2;
                 this.showmaximoch=0;
                 this.sidebaractive=0;
                 this.mainfirstheader='1';
            }
            else if(page=="/work-management")
            {
                 this.Headertypeshow=2; 
                 this.showactive=2;
                 this.showmaximoch=0; 
                 this.sidebaractive=0; 
                 this.mainfirstheader='1';
            }
            else if(page=="/assets-management")
            {
                  this.Headertypeshow=2;
                  this.showactive=2;
                  this.showmaximoch=0;
                  this.sidebaractive=0;
                  this.mainfirstheader='1';
            }
            else if(page=="/clients")
            {
                  this.Headertypeshow=2;
                  this.showactive=3;
                  this.showmaximoch=0;
                  this.sidebaractive=0;
                  this.mainfirstheader='1';
            }
            else if(page=="/changepriority")
            {
                  this.Headertypeshow=2;
                  this.showactive=3;
                  this.showmaximoch=0;
                  this.sidebaractive=2;
                  this.mainfirstheader='1';
            }
            else if(page=="/add-new-user")
            {
                  this.Headertypeshow=2;
                  this.showactive=4;
                  this.showmaximoch=0;
                  this.sidebaractive=2;
                  this.mainfirstheader='1';
            }
            else if(page=="/add-new-client")
            {
                 this.Headertypeshow=2;
                 this.showactive=3;
                 this.showmaximoch=0;
                 this.sidebaractive=2;
                 this.mainfirstheader='1';
            }
            else if(page=="/users")
            {

                 this.Headertypeshow=2;
                 this.showactive=4;
                 this.showmaximoch=0;
                 this.sidebaractive=2;
                 this.mainfirstheader='1';
                 
            }
            else if(page=="/editprofile")
            {
                 this.Headertypeshow=2;
                 this.showactive=1;
                 this.showmaximoch=0;
                 this.sidebaractive=2;
                 this.mainfirstheader='1';
            }
            else if(page=="/edituser")
            {
                   this.Headertypeshow=2;
                 this.showactive=4;
                 this.showmaximoch=0;
                 this.sidebaractive=2;
                 this.mainfirstheader='1';
            }
            else
            {
                  this.Headertypeshow=2;
                 this.showactive=3;
                 this.showmaximoch=0;
                 this.sidebaractive=2;
                 this.mainfirstheader='1';
            } 
             let userdata = JSON.parse(localStorage.getItem('localuserdata') || '{}');
          
            if(userdata)
            {
                  this.userdetils.user_id=userdata.id;
                  this.userdetils.image = userdata.profileImage;
            }
           // 
           setTimeout(function(){
             $("#mainpagecs").removeClass("notshow");
            },200);
           


      }





      loadpagefuntowew()
      {
         
          this.token=localStorage.getItem('usertoken');
            this.rest.Authodatagetservice("maximo",this.token).subscribe( res => {
                  let data:any=res;
                  
                  if(data.instances)
                  {
                      this.MaximoList=data.instances; 
                  }
                  else
                  {
                        this.MaximoList=[]; 
                        this.Maximo_ID = '';  
                  }
            },error => {
                
               this.loaderService.chnagetolloader('false');
            });
      }
      Changemaximotop(ev:any,ct:any)
      {
            if(ct==1)
            {
                  this.Org_id='';
                  this.OrgName='';
                  this.Site_id='';
            }
            this.Maximo_ID=ev;
            this.addmaximo();
            this.Site_list=[];
            this.Org_list=[];
         
            
            //this.loaderService.chnagetolloader('true');
            this.rest.Authodatagetservice("maximo/"+ev+"/organization",this.token).subscribe( res => {
                  let data:any= res;
                  this.loaderService.chnagetolloader('false');
                  if(data.organizations.length > 0)
                  {
                        this.Org_list=data.organizations;
                        if(ct==2)
                        {
                              this.ChangeOrgnigationtop(this.Org_id,2);
                        }
                  }
                  else
                  {
                        this.Org_list=[];  
                  }
            },error => {
                  this.loaderService.chnagetolloader('false');
                  
            }); 
      }
      addmaximo()
      {
            
            var maximo = this.MaximoList;
            for (let i = 0; i < maximo.length; i++) {
                  if(this.Maximo_ID==maximo[i].id)
                  {
                        this.MaximoName=maximo[i].name;
                  }
                  
            }
          
      }
      ChangeOrgnigationtop(eve:any,ct:any)
      {
            this.Org_id=eve;
            this.addOrgnigation();
            this.Site_list=[];
            console.log(eve);
            console.log(ct +"cttttttttttt");
            if(ct==1)
            {
                  this.Site_id='';
                  this.SiteName='';
            }
            //this.loaderService.chnagetolloader('true');
            this.rest.Authodatagetservice("maximo/"+this.Maximo_ID+"/organization/"+eve+"/site",this.token).subscribe( res => {
                  let data:any= res;
                  
                  
                  this.loaderService.chnagetolloader('false');
                  if(data.sites.length > 0)
                  {
                        this.Site_list=data.sites;
                        console.log(this.Site_id);
                  }
                  else
                  {
                        this.Site_list=[];  
                  }
            },error => {
                  this.loaderService.chnagetolloader('false');
                 
            }); 
      }
      addOrgnigation()
      {
            var org = this.Org_list;
            for (let i = 0; i < org.length; i++) {
                  if(this.Org_id==org[i].id)
                  {
                        this.OrgName=org[i].name;
                  }
                  
            }
            
      }
      
      ChangeSitefun(nt:any)
      {
            this.Site_id=nt;
            var org = this.Site_list;
            for (let i = 0; i < org.length; i++) {
                  if(this.Site_id==org[i].id)
                  {
                        this.SiteName=org[i].name;
                  }
                  
            }
            
      }

      Addallmaximoadorg()
      {
            if(this.Maximo_ID=='')
            {
                  this.MaximoName="";
            }
            else
            {
                  if(this.Org_id=='')
                  {
                        this.OrgName="";
                  }
                  if(this.Site_id=='')
                  {
                        this.SiteName="";
                  }
                  let data =  
                  {
                        mid : this.Maximo_ID,
                        mname : this.MaximoName,
                        oid : this.Org_id,
                        oname : this.OrgName,
                        sid : this.Site_id,
                        sname : this.SiteName
                  };
                  localStorage.setItem('Setup_value','1');
                  localStorage.setItem('Maximo_data',JSON.stringify(data));
                  this.maximoService.chnagetolloader('0');
                  this.closebtnpop=0;
                  this.changemaximopoup=0;
            }
      }

      gotoaddmaximo()
      {
            this.changemaximopoup=1;
      }
      closeaddmaximo(){
            this.changemaximopoup=0;
            let maximodata = JSON.parse(localStorage.getItem('Maximo_data') || '{}');
            
            if(maximodata)
            {
                  this.Maximo_ID=maximodata.mid;
                  this.MaximoName=maximodata.mname;
                  this.Org_id=maximodata.oid;
                  this.OrgName=maximodata.oname;
                  this.Site_id=maximodata.sid;
                  this.SiteName=maximodata.sname;
                  
            }
      }

      gotologout()
      {
            localStorage.removeItem('isloginuser');
            localStorage.removeItem('usertoken');
            localStorage.removeItem('userdata');
            this.sidebarpopup=0;
            localStorage.removeItem('Maximo_data');
            
            this.route.navigate(["/login"]);
      }
      gotohomepage()
      {
          this.route.navigate(["/home"]);
      }
      gotouserspage()
      {
            localStorage.setItem('Pagechange','1');
            this.maximoService.chnagetolloader('0');   
      }
      gotoaddnewuserspage()
      {
         this.route.navigate(["/add-new-user"]);
          
      }
      gotochangepropage()
      {
          console.log('app-com');
          localStorage.setItem('Pagechange','2');
            this.maximoService.chnagetolloader('0'); 
      }
      gotoreportpage()
      {
           this.route.navigate(["/report"]);
      }
 	
}
