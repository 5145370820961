import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { HeaderComponent } from './header/header.component';

@NgModule({
     declarations: [
          AppComponent,
          HeaderComponent
     ],
     imports: [
          BrowserModule,
          AppRoutingModule,
          HttpClientModule,
          FormsModule,
          NgMultiSelectDropDownModule.forRoot(),
          ReactiveFormsModule,
          DragDropModule
        
     ],
     providers: [],
     bootstrap: [AppComponent]
})
export class AppModule { }
