import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map'
import 'rxjs/Rx';

import * as $ from "jquery";



@Injectable({
  providedIn: 'root'
})
export class RestService {


      serviceurl = "https://api.mavenasset.com/";
      imagePath ="https://api.mavenasset.com/media/";
      httpOptions : any;
      loading : any;
      isLoading = false;
      constructor(public http : HttpClient) { 

      }
      
      serverdataget(service:any)
      {
            
            return this.http.get(this.serviceurl+service);
      }
      serverdataposttwo(service:any,servicedata:any){
            
            let mydata = new FormData();

            if(servicedata.length > 0){
                  let i=0;
                  for (let obj of servicedata)
                  {
                        for (let key in obj) 
                        {
                              mydata.append(key, obj[key]);
                        }
                        i++;
                  }
            }
            console.log(mydata)
            return this.http.post(this.serviceurl+service, mydata);
      }
      Authodatagetservice(service:any,token:any)
      {
            const httpOptions = {
                  headers: new HttpHeaders({ 'Authorization': token })
            };
            return this.http.get(this.serviceurl+service, httpOptions);
      }
     
      Authodatapostservice(service:any,servicedata:any,token:any)
      {
            const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token });

            return this.http.post(this.serviceurl+service, servicedata, { headers: myHeaders });
      }
      AuthodataImagepostservice(service:any,servicedata:any,token:any)
      {
            const myHeaders = new HttpHeaders({ 'Content-Type': 'multipart/form-data', 'Authorization': token });

            return this.http.post(this.serviceurl+service, servicedata, { headers: myHeaders });
      }
      AuthodataPUTservice(service:any,servicedata:any,token:any)
      {
            const myHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token });

            return this.http.put(this.serviceurl+service, servicedata, { headers: myHeaders });
      }
      
      AuthodataDELETEservice(service:any,servicedata:any,token:any)
      {
            const options = {
                  headers: new HttpHeaders({
                      'Content-Type': 'application/json',
                      'Authorization': token,
                  }),
                  body: servicedata
            };
            return this.http.delete(this.serviceurl+service, options);
      }
      

      
      
      
}
